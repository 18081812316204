<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Promocja</b>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="promotionHeader.name">
          <template v-slot:preview>
            <div>
              <b-tabs content-class="mt-3">
                <b-tab title="Nagłówek" active>
                  <!-- Begin Promotion Header Form -->
                  <form
                    class="form mt-10"
                    novalidate="novalidate"
                    id="promotion_header_form"
                  >
                    <b-row>
                      <b-col> </b-col>
                      <b-col> </b-col>
                      <b-col> </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="8">
                        <b-form-group
                          id="fieldsetName"
                          label="Promocja - nazwa"
                          label-for="name"
                        >
                          <b-form-input
                            v-model="promotionHeader.name"
                            name="name"
                            ref="name"
                            placeholder="nazwa"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          id="fieldset-1"
                          label="Status"
                          label-for="status"
                        >
                          <b-form-select
                            v-model="promotionHeader.status"
                            name="status"
                            ref="status"
                            :options="promotionStatus.items"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="8">
                        <b-form-group
                          id="fieldsetDescription"
                          label="Opis"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="textarea"
                            v-model="promotionHeader.description"
                            name="description"
                            ref="description"
                            placeholder="dodatkowy opis promocji widoczny na dashboard uczestnika"
                            rows="14"
                            max-rows="14"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          id="dateFrom"
                          label="Start promocji"
                          label-for="startDate"
                        >
                          <b-form-input
                            v-model="promotionHeader.startDate"
                            name="startDate"
                            ref="startDate"
                            type="date"
                            placeholder=""
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="dateTo"
                          label="Koniec promocji"
                          label-for="endDate"
                        >
                          <b-form-input
                            v-model="promotionHeader.endDate"
                            name="endDate"
                            ref="endDate"
                            type="date"
                            placeholder=""
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          id="newConverter"
                          label="Konwerter"
                          label-for="value"
                        >
                          <b-form-input
                            v-model="promotionHeader.value"
                            size="lg"
                            name="value"
                            ref="value"
                            type="number"
                            placeholder="promocyjny konwerter"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Widoczność" label-for="value">
                          <b-form-checkbox
                            size="lg"
                            v-model="promotionHeader.public"
                            name="promotion-visibility"
                            switch
                          >
                            pokaz na pulpicie uczestnika
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <b-col cols="4"
                        ><b-form-group label="SMS" label-for="value">
                          <b-form-checkbox
                            size="lg"
                            v-model="promotionHeader.autoSms"
                            name="auto-sms"
                            switch
                          >
                            automatyczna informacja SMS
                          </b-form-checkbox>
                        </b-form-group></b-col
                      >
                      <b-col cols="4">
                        <b-form-group
                          label="Powiadomienie dni przed startem promocji"
                          label-for="value"
                        >
                          <b-form-input
                            v-model="promotionHeader.smsDaysBefore"
                            name="smsDaysBefore"
                            ref="smsDaysBefore"
                            type="number"
                            placeholder="promocyjny konwerter"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <button
                      v-if="updateState === 'loaded'"
                      @click="updatePromotionHeader"
                      class="btn btn-primary float-right"
                      ref="saveButton"
                    >
                      Zapisz
                    </button>
                    <button
                      v-else
                      @click="updatePromotionHeader"
                      class="btn btn-primary float-right"
                      ref="saveButton"
                    >
                      Trwa Zapis
                      <b-icon
                        icon="three-dots"
                        animation="cylon-vertical"
                      ></b-icon>
                    </button>
                    <router-link to="/promotions">
                      <a class="btn btn-secondary float-right"
                        >Powrót to listy promocji</a
                      >
                    </router-link>
                  </form>
                </b-tab>
                <b-tab title="Pozycje">
                  <p class="mt-10">
                    <b-row>
                      <b-col>
                        <p>
                          <b-button
                            variant="outline-success"
                            size="sm"
                            @click="selectAllRows"
                            ><b-icon
                              icon="check-all"
                              aria-hidden="true"
                            ></b-icon>
                            zaznacz</b-button
                          >
                          <b-button
                            variant="outline-danger"
                            size="sm"
                            @click="clearSelected"
                            >odznacz</b-button
                          >
                          <b-button variant="danger" @click="deleteSelected"
                            ><b-icon icon="trash" aria-hidden="true"></b-icon>
                            usuń zaznaczone</b-button
                          >
                          <b-button
                            variant="success"
                            size="lg"
                            @click="addItemModal()"
                            ><b-icon icon="plus" aria-hidden="true"></b-icon>
                            dodaj towar/y</b-button
                          >
                        </p>
                      </b-col>
                      <b-col lg="6" class="my-1">
                        <b-form-group
                          label="Filtuj po nazwie"
                          label-for="filter-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filterDetails"
                              type="search"
                              placeholder="szukaj..."
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary"
                                ><b-icon icon="search"></b-icon
                              ></b-button>
                            </b-input-group-append>
                            <b-input-group-append>
                              <b-button
                                :disabled="!filterDetails"
                                @click="filterDetails = ''"
                                >wyczyść</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-table
                        selectable
                        responsive
                        striped
                        :items="promotionTowary.items"
                        :fields="promotionTowary.fields"
                        ref="promotionDetailsTable"
                        @row-selected="onRowSelected"
                        :filter="filterDetails"
                      >
                        <!-- Example scoped slot for select state illustrative purposes -->
                        <template #cell(selected)="{ rowSelected }">
                          <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                          </template>
                          <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                          </template>
                        </template>
                      </b-table>
                    </b-row>
                  </p></b-tab
                >
              </b-tabs>
            </div>
            <div>
              <div
                class="d-flex justify-content-center mb-3"
                v-if="state === 'loading'"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
    <!-- Begin sku modal -->
    <template>
      <b-modal
        id="addPromotionSku"
        size="xl"
        scrollable
        ref="detailPromotion"
        title="Wybór towarów"
      >
        <b-container>
          <!-- begin checkboxes -->
          <b-row>
            <b-col>
              <b-form-checkbox
                @change="searchSkus"
                v-model="checkedGroups"
                value="1"
                name="check-button"
                switch
                size="lg"
                button-variant="success"
              >
                <b-badge variant="success">Grupa 1 = 240</b-badge>
              </b-form-checkbox>
              <b-form-checkbox
                @change="searchSkus"
                v-model="checkedGroups"
                value="2"
                name="check-button"
                switch
                size="lg"
                button-variant="info"
              >
                <b-badge variant="info">Grupa 2 = 160</b-badge>
              </b-form-checkbox>
              <b-form-checkbox
                @change="searchSkus"
                v-model="checkedGroups"
                value="3"
                name="check-button"
                switch
                size="lg"
                button-variant="warning"
              >
                <b-badge variant="warning">Grupa 3 = 80</b-badge>
              </b-form-checkbox>
              <b-form-checkbox
                @change="searchSkus"
                v-model="checkedGroups"
                value="4"
                name="check-button"
                switch
                size="lg"
                button-variant="secondary"
              >
                <b-badge variant="secondary">Grupa 4 = 40</b-badge>
              </b-form-checkbox>
              <b-form-checkbox
                @change="searchSkus"
                v-model="checkedGroups"
                value="5"
                name="check-button"
                switch
                size="lg"
                button-variant="primary"
              >
                <b-badge variant="primary">Grupa 5 = 8</b-badge>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- begin input text search -->
          <b-row>
            <b-col></b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filtuj po nazwie"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="szukaj..."
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      :disabled="!filter"
                      @click="searchSkus"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="clearFilter"
                      >wyczyść</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- begin pagination -->
          <b-row>
            <b-col sm="12" md="12" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @change="getSku"
                align="right"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>

          <p>
            <b-button variant="success" size="sm" @click="selectAllRowsOnModal"
              >zaznacz wszystko</b-button
            >
            <b-button
              variant="outline-warning"
              size="sm"
              @click="clearSelectedOnModal"
              >wyczyść zaznaczenie</b-button
            >
          </p>

          <b-table
            ref="promotionskustable"
            responsive
            striped
            hover
            selectable
            :items="promotionSkus.items"
            :fields="promotionSkus.fields"
            :tbody-tr-class="rowClass"
            v-if="state === 'loaded'"
            @row-selected="onModalRowSelected"
            :filter="filter"
          >
            <template #cell(selected)="{ modalRowSelected }">
              <template v-if="modalRowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-container>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button
            variant="success"
            size="sm"
            class="float-right"
            @click="addSelectedSku"
          >
            Akceptuj
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="hideModal()"
          >
            Zamknij
          </b-button>
        </template>
      </b-modal>
    </template>
    <!-- Begin after add modal -->
    <template>
      <b-modal
        size="xl"
        scrollable
        id="addDetailResultModal"
        ref="addDetailResult"
        title="Rezultat"
        ok-only
        ok-title="Rozumiem"
      >
        <div class="d-block">
          <div class="alert alert-danger" role="alert">
            Towary nie dodane do promocji - istnieją w tym samym okresie na
            innej promocji.
          </div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nazwa Promocji</th>
                <th scope="col">Kod Towaru</th>
                <th scope="col">Nazwa Towaru</th>
                <th scope="col">Błąd</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in saveDetailResult">
                <tr :key="i">
                  <td>{{ item.promotionName }}</td>
                  <td>{{ item.skuCode }}</td>
                  <td>{{ item.skuName }}</td>
                  <td>
                    <b-icon-bug
                      variant="danger"
                      animation="cylon-vertical"
                    ></b-icon-bug>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import axios from "axios";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import StartEndDate from "@/assets/plugins/formvalidation/dist/es6/plugins/StartEndDate";
import Swal from "sweetalert2";

export default {
  name: "promotion",

  data() {
    return {
      state: "loading",
      updateState: "loaded",
      headerVisible: true,
      modalState: "loading",
      promotionTowary: {
        fields: [
          {
            key: "promotionSkus.code",
            label: "Kod",
            sortable: true,
          },
          {
            key: "promotionSkus.name",
            label: "Nazwa",
            sortable: true,
          },
          {
            key: "promotionSkus.um",
            label: "j.m.",
            sortable: true,
          },
          {
            key: "promotionSkus.skuGroupName",
            label: "Grupa Podstawowa",
            sortable: true,
          },
          {
            key: "promotionSkus.pjgroup",
            label: "Agropula Grupa",
            sortable: true,
          },
          {
            key: "promotionSkus.points",
            label: "Konwerter",
            sortable: true,
          },
          {
            key: "selected",
            label: "zaznacz",
          },
        ],
        items: [],
      },
      selected: [],
      totalRows: null,
      currentPage: null,
      perPage: null,
      filter: "",
      filterDetails: null,
      checkedGroups: ["1", "2", "3", "4", "5"],
      promotionSkus: {
        fields: [
          {
            key: "code",
            label: "Kod Produktu",
            sortable: true,
          },
          {
            key: "name",
            label: "Nazwa Produktu",
            sortable: true,
          },
          {
            key: "um",
            label: "jm",
            sortable: true,
          },
          {
            key: "skuGroupName",
            label: "Grupa Towarowa",
            sortable: true,
          },
          {
            key: "pjgroup",
            label: "Grupa",
            sortable: true,
          },
          {
            key: "points",
            label: "Punktacja",
            sortable: true,
          },
          {
            key: "actions",
            label: "wybrany",
          },
        ],
        items: [],
      },
      selectedOnModal: [],
      promotionHeader: {},
      promotionStatus: {
        items: [
          { value: "BUFOR", text: "BUFOR" },
          { value: "POTWIERDZONA", text: "POTWIERDZONA" },
        ],
      },
      autoSmsChecked: false,
      saveDetailResult: [],
    };
  },
  components: {
    KTCodePreview,
  },
  methods: {
    getPromotion(id) {
      ApiService.get(`/promotion/${id}`).then((response) => {
        this.promotionTowary.items = response.data.details;
        this.state = "loaded";
        this.promotionHeader = {
          id: response.data.id,
          name: response.data.name,
          description: response.data.description,
          startDate: response.data.startDate,
          endDate: response.data.endDate,
          status: response.data.status,
          public: response.data.public,
          autoSms: response.data.autoSms,
          smsDaysBefore: response.data.smsDaysBefore,
          value: response.data.value,
        };
      });
    },
    addItemModal() {
      this.$refs.detailPromotion.show("addPromotionSku");
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onModalRowSelected(items) {
      this.selectedOnModal = items;
    },
    selectAllRows() {
      this.$refs.promotionDetailsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.promotionDetailsTable.clearSelected();
    },
    selectAllRowsOnModal() {
      this.$refs.promotionskustable.selectAllRows();
    },
    clearSelectedOnModal() {
      this.$refs.promotionskustable.clearSelected();
    },
    deleteSelected() {
      let ids = this.selected.map((obj) => obj.id);

      if (ids.length > 0) {
        Swal.fire({
          title: "Jesteś pewny?",
          icon: "question",
          text: "Operacja nieodwracalna",
          showCancelButton: true,
          confirmButtonColor: "#009136",
          confirmButtonText: "Tak, usuń",
          cancelButtonText: "Nie",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post("/promotion/details/delete", ids)
              .then(() => {
                this.promotionTowary.items = this.promotionTowary.items.filter(
                  (obj) => {
                    return !ids.includes(obj.id);
                  }
                );
              })
              .catch(() => {
                console.log("catch");
              });
          }
        });
      } else {
        Swal.fire({
          title: "Błąd",
          text: "Brak zaznaczonych pozycji do usunięcia!",
          icon: "error",
          confirmButtonColor: "#009136",
        });
      }
      // this.selected.filter(function(obj) {
      //   return obj.id !== 4;
      // });
    },
    getSku(page = 1) {
      this.state = "loading";

      axios
        .get("promotion-skus-pagable", {
          params: {
            page: page,
            filter: this.filter,
            groups: this.checkedGroups,
          },
        })
        .then((response) => {
          this.promotionSkus.items = response.data.content;
          this.totalRows = response.data.totalElements;
          this.perPage = response.data.size;
          this.currentPage = page;

          this.state = "loaded";
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.pjgroup === "1") return "table-success";
      if (item.pjgroup === "2") return "table-info";
      if (item.pjgroup === "3") return "table-warning";
      if (item.pjgroup === "4") return "table-secondary";
      if (item.pjgroup === "5") return "table-primary";
    },
    searchSkus() {
      this.getSku();
    },
    clearFilter() {
      this.filter = "";
      this.getSku();
    },
    hideModal() {
      this.$refs.promotionskustable.clearSelected();
      this.$refs["detailPromotion"].hide();
    },
    updatePromotionHeader(event) {
      event.preventDefault();
      const promotion_header_form = KTUtil.getById("promotion_header_form");
      this.promotionHeaderForm = formValidation(promotion_header_form, {
        fields: {
          name: {
            validators: {
              notEmpty: {
                message: "Proszę wpisać nazwę",
              },
            },
          },
          startDate: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać datę startu promocji",
              },
              date: {
                min: "YYYY/MM/DD",
                message: "The value is not a valid date",
              },
            },
          },
          endDate: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać datę końca promocji",
              },
            },
          },
          description: {
            validators: {
              notEmpty: {
                message:
                  "Opisz promocję, opis może być widoczny na pulpicie uczestnika",
              },
            },
          },
          smsDaysBefore: {
            validators: {
              notEmpty: {
                message:
                  "Ustaw wartość dni nawet jeśli nie zaznaczono auto SMS",
              },
              numeric: {
                message: "Pole powinno być liczbą",
              },
              greaterThan: {
                inclusive: true,
                min: 1,
                message: "Wyślij SMS min 1 dzień wcześniej",
              },
            },
          },
          value: {
            validators: {
              notEmpty: {
                message: "Promocyjny konwerter nie może być pusty",
              },
              numeric: {
                message: "Konwerter to liczba!",
              },
              greaterThan: {
                inclusive: false,
                min: 0,
                message: "Konwerter musi być większy od zera",
              },
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
          startEndDate: new StartEndDate({
            format: "YYYY-MM-DD",
            startDate: {
              field: "startDate",
              message:
                "Data startu musi być wcześniejsza od daty końca promocji",
            },
            endDate: {
              field: "endDate",
              message:
                "Data końca musi być późniejsza od daty począ†ku promocji",
            },
          }),
        },
      });

      this.promotionHeaderForm.validate().then((v) => {
        if (v === "Valid") {
          this.updateState = "loading";
          ApiService.post("/promotion/edit", this.promotionHeader)
            .then(() => {
              this.updateState = "loaded";
              this.$router.push({ name: "promotions" });
              this.$refs.saveButton.textContent = "Trwa zapis ...";
            })
            .catch(() => {
              console.log("catch");
            });
        }
      });
    },
    addSelectedSku() {
      let skuRequest = this.selectedOnModal.map((v) => ({
        promotion_id: parseInt(this.$route.params.id),
        promotionskus_id: v.id,
      }));

      if (skuRequest.length > 0) {
        ApiService.post("/promotion/details/add", skuRequest)
          .then((response) => {
            this.saveDetailResult = response.data;

            if (response.data.length > 0) {
              Swal.fire({
                title: "Błąd",
                icon: "error",
                text: "Nie wszystkie wybrane towary zostały dodane do promocji",
                showCancelButton: true,
                confirmButtonColor: "#009136",
                confirmButtonText: "Zobacz szczegóły",
                cancelButtonText: "Rozumiem",
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$refs.addDetailResult.show();
                }
              });
            } else {
              Swal.fire({
                title: "Info",
                text: "Operacja zakończona sukcesem",
                confirmButtonColor: "#009136",
                icon: "info",
              });
            }
            //TODO add items to existing array DO NOT call api again

            this.getPromotion(this.$route.params.id);
            this.$refs.promotionskustable.clearSelected();
            this.$refs.detailPromotion.hide("addPromotionSku");
          })
          .catch(() => {
            console.log("catch");
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Promocje" },
      { title: "" },
    ]);

    this.getPromotion(this.$route.params.id);
    this.getSku();
  },
  computed: {
    computedselected() {
      return this.promotionTowary.items.filter((sku) => sku.selected);
    },
  },
};
</script>

<style></style>
